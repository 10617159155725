// @import "../../assets/style/variables";

// // .main:hover{
// //   background: rgba(217, 217, 217, 0.6);
// //  border: 1px solid #D1D1D1;
// //  border-radius: 50%;
// //  outline: none;
// //  box-shadow: none;
// // }
// .main {
//   background: white;
//   border: none;
//   color: black;
// }
// .main::after {
//   display: none;
// }
// .menu {
//   background: #ffffff;
//   opacity: 0.98;
//   border-radius: 6px;
//   font-family: "Montserrat", sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 14px;
//   line-height: 17px;
//   /* identical to box height */
//   outline: none;
//   box-shadow: none;

//   color: #000000;
//   z-index: 99;
// }

// .add-user-model {
//   .modal-dialog {
//     min-width: 1040px;
//     .modal-content {
//       background: transparent;
//       border: none;
//     }
//   }
// }
// //Carousel slide

// .slide {
//   width: 100%;
//   min-height: 800px;
//   background: #f1f2f3;
//   border-radius: 10px;
// }

// .first-slide {
//   display: flex;
//   justify-content: space-evenly;
//   align-items: center;
// }

// .slide-heading {
//   width: 302px;
//   height: auto;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 28px;
//   line-height: 33px;
//   color: #007aff;
//   margin-bottom: 0;
// }

// .slide-paragraph {
//   width: 300px;
//   height: auto;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 32px;
//   line-height: 38px;
//   color: #000000;
//   margin-bottom: 25px;
//   text-overflow: ellipsis;
//   overflow: hidden;
// }

// .last-heading {
//   margin-left: 13.5%;
// }
// .first-btn {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 10px 32px;
//   background: #39b54a;
//   border-radius: 8px;
//   outline: none;
//   box-shadow: none;
//   border: none;
// }
// .first-btn:hover {
//   background: #39b54a;
// }
// .carousel-indicators {
//   border-bottom: none !important;
//   button {
//     width: 12px !important;
//     height: 2px !important;
//     background: #d9d9d9 !important;
//     border-radius: 50%;
//     border-bottom: none !important;
//     margin-left: 5px !important;
//     margin-right: 5px !important;
//   }
//   .active {
//     background: black !important;
//   }
// }

// .violations-container {
//   position: relative;
//   margin: 18px 48px;
//   background: #ffffff;
//   border: 1px solid #d1d1d1;
//   border-bottom: none;
//   border-radius: 10px;

//   .arrow-user {
//     position: absolute;
//     position: absolute;
//     left: 94.9%;
//     width: 48px;
//     height: 25px;
//   }

//   div {
//     background-color: white;
//     border-radius: 10px 10px 0 0;

//     &:first-child {
//       border-radius: 10px 0px 0px 0px !important;
//       .label-v {
//         border-radius: 10px 0px 0px 0px !important;
//       }
//       .label-a {
//         border-radius: 0px 10px 0px 0px !important;
//       }
//     }

//     &:last-child {
//       border: none;
//       border-radius: 0 0 10px 10px;
//       // border-radius: 0px 10px 0px 0px !important;
//       .label-v {
//         border-radius: 0 0 0 10px !important;
//       }
//       .label-a {
//         border-radius: 0 0px 10px 0 !important;
//       }
//     }

//     .label-v {
//       width: 28%;
//       display: flex;
//       justify-content: flex-end;
//       align-items: center;
//       font-style: normal;
//       font-weight: 800;
//       font-size: 20px;
//       line-height: 24px;
//       color: #007aff;
//       border-right: 1px solid #d1d1d1;
//       padding: 10px 8px;
//       border-radius: 0;
//       border-bottom: 1px solid #d1d1d1;
//     }
//     .label-a {
//       width: 72%;
//       display: flex;
//       justify-content: flex-start;
//       align-items: center;
//       padding: 10px 8px;
//       font-weight: 500;
//       font-size: 20px;
//       line-height: 24px;
//       color: #000000;
//       border-radius: 0 10px 0 0;
//       border-bottom: 1px solid #d1d1d1;
//     }
//   }
// }

// .invites-container {
//   display: flex;
//   margin: 25px 48px;
//   justify-content: space-between;

//   .invite-card {
//     width: 48%;
//     background-color: #ffffff;
//     border: 1px solid #d1d1d1;
//     border-radius: 10px;
//     border-bottom: 0;
//     position: relative;
//     margin-bottom: 25px;

//     .arrow-user {
//       width: 48px;
//       height: 27px;
//       position: absolute;
//       left: 88.98%;
//       top: -0.5px;
//     }
//     div {
//       .invite-l {
//         width: 40%;
//         font-weight: 800;
//         font-size: 20px;
//         line-height: 24px;
//         color: #007aff;
//         display: flex;
//         align-items: center;
//         justify-content: flex-end;
//         padding: 5px 5px 10px 0;
//         border-right: 1px solid #d1d1d1;
//         border-bottom: 1px solid #d1d1d1;
//       }
//       .invite-a {
//         width: 60%;
//         font-weight: 500;
//         font-size: 20px;
//         line-height: 24px;
//         padding: 2px 15px;
//         color: #000000;
//         display: flex;
//         align-items: center;
//         justify-content: flex-start;
//         border-bottom: 1px solid #d1d1d1;
//       }

//       &:last-child {
//         .invite-l {
//           border-radius: 0 0 0 10px;
//         }

//         .invite-a {
//           border-radius: 0 0 10px 0;
//         }
//       }
//     }
//   }
// }

// .user-subscription {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 10px;
//   .user-input-count {
//     filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
//     width: 53px;
//     height: 28px;
//     background: #f2f2f2;
//     border-radius: 5px;
//     box-shadow: none;
//     border: 0;

//     &::-webkit-inner-spin-button {
//       -webkit-appearance: none;
//       background: #fff
//         url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAcCAYAAACH81QkAAAAAXNSR0IArs4c6QAAAVxJREFUSEu11E0oRFEYxvH/kZAZzUINC6nZkNixkI1irdRYyYpYSKbUWEiSxZBZ3M2wkaWdj0QpUVLC9mZKLGZIYqFwy2gwV9c0acxH9+M46/f8zjlvz3sEU7qO1aUTR7BMSISNrcIW8nvoJiHhd4qAzogMJOocgWRRJNABdR4I7hXvfEHE3wwzXXCvwf41KCeFobxIpw/W+8EXhs8UnI/C3CFsRPNDOYjXBeo4NCrw8p7e5C6DWBDaluDmORfKQUpLoNYNd6/ZxZ4KSHxA8ssEYjW8MhL7c6aMnCBmD2wM4J83y7mJwymW2JN/uYmR2MFWWDjO7t5YO+xcWkjsRQAipxA5S0N9LbDSC/WLoCVNJraqHG4nYXgLHjTYHoAGBZ7eTA5gpqy6Eq4mIJWC7lVQHy1+BZnyJi/UuOAoZvNTMj2IOgkZiVVlIEPOEJ1d5kWPXSQOrBES00bvvgGk5ZN9E/qwQQAAAABJRU5ErkJggg==)
//         no-repeat 10% 40%;
//       width: 17px;
//       height: 28px;
//       border-left: 1px solid #bbb;
//       opacity: 1; /* shows Spin Buttons per default (Chrome >= 39) */
//       position: absolute;
//       top: 0;
//       right: 0;
//       bottom: 0;
//     }
//   }
// }

// .badge-users {
//   width: 20px;
//   height: 20px;
//   font-weight: 500;
//   font-size: 12px;
//   line-height: 14px;
//   color: #ffffff;
//   border-radius: 50%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .badge-users-lg {
//   width: 45px;
//   height: 45px;
//   font-weight: 500;
//   font-size: 30px;
//   line-height: 36px;
//   color: #ffffff;
//   border-radius: 50%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .add-content-tab {
//   background-color: white;
//   margin-left: 10px;
//   border: 0;
//   border-radius: 10px 10px 0px 0px;
//   height: 70px;
//   .nav-item {
//     .nav-link {
//       border: none;
//       height: 100%;
//       font-style: normal;
//       font-weight: 600;
//       font-size: 20px;
//       line-height: 29px;
//       color: black;
//       border-bottom: 1px solid #d1d1d1;
//       border-radius: 0;
//       transition: all 0.3s ease-in;

//       &.active {
//         background-color: $primary;
//         color: $white;
//         border-radius: 0;
//       }
//     }

//     &:first-child {
//       .nav-link.active {
//         background: #007aff;
//         border-radius: 10px 0px 0px 0;
//         transition: all 0.3s ease-out;
//       }
//     }

//     &:last-child {
//       .nav-link.active {
//         background: #007aff;
//         color: white;
//         border-radius: 0px 10px 0px 0;
//         transition: all 0.3s ease-out;
//       }
//     }

//     .custom-tab-content-red {
//       &.active {
//         background-color: #e43940 !important;
//       }
//     }
//   }
// }

// .livetake-card {
//   background-color: #1e1e1e;
//   .title {
//     color: white;
//     text-transform: uppercase;
//     font-size: 16px;
//     width: 75%;
//     text-align: center;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
//   .title-icon {
//     color: #6e6e6e;
//   }
//   .user-pic {
//     border: 1px solid white;
//     margin-bottom: 10px;
//   }
//   .user-title {
//     color: white;
//     font-size: 12px;
//   }
//   .vs-text {
//     color: #6e6e6e;
//   }
//   .opponent-pic {
//     border: 1px solid white;
//     margin-bottom: 10px;
//   }
//   .opponent-title {
//     color: white;
//     font-size: 12px;
//   }
//   .user-des {
//     border: 1px solid white;
//     background-color: #2e2e2e;
//     padding: 20px;
//     border-radius: 30px 0px 0px 30px;
//     font-size: 14px;
//     color: white;
//     text-transform: uppercase;
//     min-width: 48%;
//     text-align: center;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     overflow: hidden;
//   }
//   .opponent-des {
//     background-color: #2e2e2e;
//     padding: 20px;
//     border-radius: 0px 30px 30px 0px;
//     font-size: 14px;
//     color: white;
//     text-transform: uppercase;
//     min-width: 48%;
//     text-align: center;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//   }
//   .user-percentage {
//     color: white;
//     sub {
//       color: #6e6e6e;
//     }
//   }
//   .opponent-percentage {
//     color: white;
//     sub {
//       color: #6e6e6e;
//     }
//   }
// }
// .user-details {
//   margin: 40px 0 30px 0;
//   .col-md-6 {
//     display: flex;
//     align-items: center;
//     padding: 2% 5%;
//     font-size: 18px;

//     strong {
//       font-size: 18px;
//     }
//   }
// }
// .custom-action {
//   button {
//     background: #1e1e1e !important;
//     border: #1e1e1e !important;
//   }
//   .dropdown-menu {
//     padding: 2px !important;
//     .dropdown-item {
//       border-radius: 5px;
//     }
//   }
// }

.user-details-navbar {
  button {
    &.btn--back {
      background-color: transparent !important;
      border: 0 !important;
    }
  }
}

.user-detail-row {
  line-height: 1.25;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  span {
    &.title {
      font-size: 12px;
      font-weight: 600;
      color: #9d9d9d;
    }
  }
}
