// Colours
$primary : #007AFF;
$primaryHover: #004085;
$secondary : #949494;
$success: #39B54A;
$white : #ffffff;
$black: #000000;
$danger:#E43940;

$base-font-size: 16px;
$root-font-size: 16;

// Break points
$smallScreen: 576px;
$mediumScreen: 768px;
$largeScreen: 992px;
$xLargeScreen: 1200px;
$xxLargeScreen: 1400px;