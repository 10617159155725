header {
  .navbar {
    height: 64px;
    padding: 0;

    .dropdown {
      filter: none !important;

      & > button {
        background-color: transparent !important;
        color: #000 !important;
        border: 0 !important;
        display: flex;
        align-items: center;
        font-size: 18px;
        padding-right: 0px;

        & > svg {
          margin-top: 4px;
          margin-left: 5px;
        }

        &:after {
          content: none;
        }
      }
    }
  }
}

.page--layout {
  display: grid;
  grid-template-columns: 250px 1fr;
  margin-top: 64px;
  height: calc(100vh - 64px);
  background-color: #f1f3f4;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;

  .sidebar {
    position: relative;
    top: 0px;
    left: 0;
    height: calc(100vh - 64px);
    overflow: auto;
    background-color: #fff;

    .sidebar-menu {
      .list-group {
        .list-group-item:first-child {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
        .list-group-item:last-child {
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }
    }
  }

  .page--content {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.btn-gradient {
  background: linear-gradient(
    90deg,
    #b0c6ff 15.63%,
    #c0b4f2 36.04%,
    #ff8bb1 84.37%
  );
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  font-size: 20px !important;
  font-weight: 600 !important;

  &:focus,
  &:focus-within,
  &:active {
    box-shadow: none !important;
    border: 0px !important;
  }
}

.btn-success {
  background-color: #39b54a !important;
  border-color: #39b54a !important;
}

.modal-backdrop .show {
  background: rgba(0, 0, 0, 0.4) !important;
}
.delete-btn {
  width: 127px;
  height: 42px;
}

.dot-typing {
  display: block;
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  animation: dotTyping 1s infinite linear;
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
  50% {
    box-shadow: 9984px 0 0 0 white, 9999px -10px 0 0 white, 10014px 0 0 0 white;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px -10px 0 0 white;
  }
  100% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
}

.dot-typing-primary {
  display: block;
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $primary;
  color: $primary;
  box-shadow: 9984px 0 0 0 $primary 9999px 0 0 0 $primary 10014px 0 0 0 $primary;
  animation: dotTypingPrimary 1s infinite linear;
}

@keyframes dotTypingPrimary {
  0% {
    box-shadow: 9984px 0 0 0 $primary, 9999px 0 0 0 $primary,
      10014px 0 0 0 $primary;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 $primary, 9999px 0 0 0 $primary,
      10014px 0 0 0 $primary;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 $primary, 9999px 0 0 0 $primary,
      10014px 0 0 0 $primary;
  }
  50% {
    box-shadow: 9984px 0 0 0 $primary, 9999px -10px 0 0 $primary,
      10014px 0 0 0 $primary;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 $primary, 9999px 0 0 0 $primary,
      10014px 0 0 0 $primary;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 $primary, 9999px 0 0 0 $primary,
      10014px -10px 0 0 $primary;
  }
  100% {
    box-shadow: 9984px 0 0 0 $primary, 9999px 0 0 0 $primary,
      10014px 0 0 0 $primary;
  }
}
.pagination {
  // position: absolute;
  // bottom: 26px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .page-item {
    .page-link {
      border: 0;
      background: $white;
      color: $black;
      font-size: 16px;
      font-weight: 600;
      border-radius: 50%;
      // padding: 10px 20px;
      width: 45px;
      height: 45px !important;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
    &:first-child,
    &:last-child {
      .page-link {
        background-color: $primary;
        color: $white;
        border-radius: 8px;
        // padding: 18px 60px;
        font-family: "thin";
        width: 122px;
        height: 45px;
      }
    }
  }
  .disabled {
    .page-link {
      opacity: 0.5;
    }
  }
  .active {
    .page-link {
      background: $primary;
      color: $white;
    }
  }
}

// Active user dropdown

.active-user-chart {
  position: relative;
}

.active-user-dropdown {
  position: absolute;
  z-index: 1;
  left: 85%;
  top: 1%;
}

.select-dropdown,
.select-dropdown * {
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
}
.select-dropdown {
  position: relative;
  background-color: #e6e6e6;
  border-radius: 4px;
}
.select-dropdown select {
  font-size: 1rem;
  font-weight: normal;
  max-width: 100%;
  padding: 8px 24px 8px 10px;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.select-dropdown select:active,
.select-dropdown select:focus {
  outline: none;
  box-shadow: none;
}
.select-dropdown:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  width: 0;
  height: 0;
  margin-top: -2px;
  border-top: 5px solid #aaa;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

table.data-table tbody tr td {
  vertical-align: middle;
}

span.verified-badge,
span.goated-badge {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../../assets/img/verified.png);
  filter: grayscale(100%);
  opacity: 0.35;

  &.active {
    filter: grayscale(0%);
    opacity: 1;
  }
}
span.goated-badge {
  background-image: url(../img/goated-icon.png);
}

span.edit-badge {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../../assets/img/editIcon.png);
  filter: grayscale(0%);
  opacity: 1;
  cursor: pointer;
}

ul[role="tablist"] {
  li {
    button {
      &.nav-link {
        text-transform: uppercase !important;
        color: #9f9f9f !important;
        border: 0 !important;
        border-bottom: 3px solid #ccc !important;
        font-weight: 600 !important;

        &.active {
          color: #0d6efd !important;
          border-bottom-color: #0d6efd !important;
        }
      }
    }
  }
}

.livetake-poll-options {
  grid-template-columns: 1fr 1fr;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 600;

  & > span {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    max-height: 24px;
    line-height: 1;
    overflow: hidden;

    &:first-child {
      &:after {
        content: " ";
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: #ccc;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
  }
}

.livetake-poll-result {
  grid-template-columns: 1fr 1fr;
  font-size: 16px;
  font-weight: 600;

  & > span {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    & > span {
      font-size: 12px;
      color: #aaa;
      margin-left: 4px;
    }
  }
}

.card--livetake {
  width: 240px;
  overflow: hidden;
  position: relative;

  .card-body {
    position: relative;
    left: 0%;
    transition: left 0.2s ease-in-out;

    &.livetake-details {
      &.hide {
        left: -100%;
      }
    }

    &.livetake-comments,
    &.livetake-delete-confirm {
      position: absolute;
      z-index: 1;
      left: 0%;
      top: 0;
      height: 100%;
      width: 100%;

      &.hide {
        left: 100%;
      }

      .card-title {
        button {
          position: absolute;
          left: 0px;
          top: -2px;
          padding: 0px 5px;
          border: 0 !important;
        }
      }

      .comments,
      .delete-confirm {
        height: calc(100% - 35px);
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    &.livetake-comments {
      .comment--card {
        border-bottom: 1px dashed #ccc;
        padding: 7px 4px;
        font-size: 13px;
        font-weight: 500;

        & > div:first-child {
          display: grid;
          grid-template-columns: 32px 1fr 20px;
          align-items: flex-start;
        }
      }
    }
  }

  .live-badge,
  .category-badge {
    position: absolute;
    right: 8px;
    top: 10px;
    font-size: 10px;
    font-weight: 500;
  }

  .live-badge {
    right: unset;
    left: 8px;
  }

  .livetake-users {
    grid-template-columns: 1fr 15px 1fr;
    grid-gap: 7px;
  }

  .livetake-attributes {
    span.d-grid {
      grid-template-columns: 60px 1fr;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      & > span:first-child {
        font-size: 13px;
        font-weight: 600;
        color: rgb(132, 132, 132);
      }

      & > span:nth-child(2) {
        font-size: 13px;
        font-weight: 600;
        color: #000;
      }
    }
  }

  .livetake-actions {
    button {
      color: $secondary !important;
      font-size: 12px !important;
      font-weight: 600 !important;
      text-decoration: none !important;

      &.btn--delete {
        color: $danger !important;
      }
    }
  }
}

.card--take {
  width: 240px;
  overflow: hidden;
  position: relative;

  .card-body {
    position: relative;
    left: 0%;
    transition: left 0.2s ease-in-out;

    &.take-details {
      &.hide {
        left: -100%;
      }
    }

    &.take-delete-confirm {
      position: absolute;
      z-index: 1;
      left: 0%;
      top: 0;
      height: 100%;
      width: 100%;

      &.hide {
        left: 100%;
      }

      .card-title {
        button {
          position: absolute;
          left: 0px;
          top: -2px;
          padding: 0px 5px;
          border: 0 !important;
        }
      }

      .delete-confirm {
        height: calc(100% - 35px);
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }

  .backed-badge,
  .category-badge {
    position: absolute;
    right: 8px;
    top: 10px;
    font-size: 10px;
    font-weight: 500;
  }

  .backed-badge {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
  }

  .livetake-users {
    grid-template-columns: 1fr 15px 1fr;
    grid-gap: 7px;
  }

  .take-attributes {
    span.d-grid {
      grid-template-columns: 90px 1fr;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      & > span:first-child {
        font-size: 13px;
        font-weight: 600;
        color: rgb(132, 132, 132);
      }

      & > span:nth-child(2) {
        font-size: 13px;
        font-weight: 600;
        color: #000;
      }
    }
  }

  .take-emojies {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    height: 41px;

    span.take-emoji {
      border: 1px solid #ccc;
      border-radius: 20px;
    }
  }

  .take-actions {
    button {
      color: $secondary !important;
      font-size: 12px !important;
      font-weight: 600 !important;
      text-decoration: none !important;

      &.btn--delete {
        color: $danger !important;
      }
    }
  }
}

.take-poll-options {
  grid-template-columns: 1fr 1fr;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 600;

  & > span {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    max-height: 24px;
    line-height: 1;
    overflow: hidden;

    &:first-child {
      &:after {
        content: " ";
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: #ccc;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
  }
}

.take-poll-result {
  grid-template-columns: 1fr 1fr;
  font-size: 16px;
  font-weight: 600;

  & > span {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    & > span {
      font-size: 12px;
      color: #aaa;
      margin-left: 4px;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $secondary;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primaryHover;
}
