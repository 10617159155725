@import "../../assets/style/variables";
// @import "../../assets/style/typography";

.change-details-container {
    
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.change-password-container{
    background-color: $white;
    border-radius: 10px;
    min-height: 450px;
    min-width: 600px;
    display: flex;
    align-items:center;
    justify-content:space-around;
    box-shadow: 0 6px 4px -8px #777;
}

.create-new-pwd {
    position: relative;
    top: -10px;
    margin: 45px 0px 25px 0px !important;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    font-family: 'Montserrat', sans-serif;
}

.change-pwd-input {
    width: 65%;
    padding: 12px 20px;
    margin: 4px 0;
    display: inline-block;
    border: none;
    border-radius: 12px;
    box-sizing: border-box;
    background-color: #F5F5F5;
    border: 1.5px solid #F5F5F5;
    font-size: 16px;
    caret-color: #39b54a;
}

.change-pwd-input::placeholder {
    color: #959595;
    font-size: 16px;
}

.change-pwd-input:focus {
    border: 1.5px solid #39b54a;
}

.change-pwd-btn {
    margin: 20px 0px 45px 0px;
    padding: 12px 20px;
    align-items: center;
    justify-content: center;
    background-color: #007AFF;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    font-size: 16px;
}

.change-pwd-btn:hover {
    background-color: #0069d8;
}

.confirmation-container{
    background-color: $white;
    border-radius: 10px;
    min-height: 40%;
    min-width: 35%;
    display: flex;
    align-items:center;
    justify-content:space-around;
    // box-shadow: 0 6px 4px -8px #777;
}

.confirmation-icon {
    max-width: 70px;
    max-height: 70px;
    margin: 40px 0px 0px 0px;
}

.confirmation-text {
    position: relative;
    margin: 30px 0px 40px 0px !important;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    font-family: 'Montserrat', sans-serif;
}