@import "../../assets/style/variables";

// Filter Dropdown Styles
.dropbtn {
    background-color: $white;
    color: $secondary;
    padding: 10px 30px 10px 20px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    width: 200px;
    border-radius: 5px;
    border: 1px solid #EFEFEF;
    text-align: left;
}

.dropbtn-1 {
    background-color: $white;
    color: $secondary;
    padding: 10px 30px 10px 20px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    width: 200px;
    border-radius: 5px;
    border: 1px solid #EFEFEF;
    text-align: left;
}


.dropdown-content {
    display: none;
    // position: absolute;
    background-color: $white;
    min-width: 200px;
    z-index: 1;
    font-size: 14px;
    border-radius: 0px 0px 5px 5px;
    border: 1px solid #EFEFEF;
    border-top: none !important;
}

.dropdown-content a {
    color: $secondary;
    padding: 8px 10px 8px 20px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: none;
    color: $primary;
}

.filter-dropdown:hover .dropdown-content {
    display: block;
    background: white;
}

.filter-dropdown-1:hover .dropdown-content {
    display: block;
}

.filter-dropdown:hover .dropbtn {
    border-radius: 5px 5px 0px 0px;
}

.filter-dropdown-1:hover .dropbtn-1 {
    border-radius: 5px 5px 0px 0px;
}

.filter-arrow-style {
    position: relative;
    margin-bottom: 1px;
    left: 40px;
}

.filter-arrow-style-1 {
    position: relative;
    margin-bottom: 1px;
    left: 20px;
}


.toggle-text {
    font-size: 20px;
    font-weight: 600;
    color: $primary;
    position: relative;
    left: 8px;
}

// .dropdown-content:hover{
//     background: white;
// }

// .dropdown-content a:hover {
//     color: $secondary;
//     padding: 8px 10px 8px 20px;
//     text-decoration: none;
//     display: block;
// }