
.cursor{
    cursor: pointer !important;
}
.bg-none{
    background: transparent !important;
}
.box-shadow-none{
    box-shadow: none !important;
}

//Font Size
.fs-14{
    font-size: 14px !important;
}
.fs-16{
    font-size: 16px !important;
}
.fs-18{
    font-size: 18px !important;
}

.fs-20{
    font-size: 20px !important;
}

.fs-22{
    font-size: 18px !important;
}

.fs-24{
    font-size: 24px !important;
}

.fs-26{
    font-size: 26px !important;
}

// Font weight 

.fw-600{
    font-weight: 600 !important;
}

.fw-700{
    font-weight: 700 !important;
}

.fw-800{
    font-weight: 800 !important;
}

// Border radius
.br-10{
    border-radius: 10px !important;
}
.br-15{
    border-radius: 15px !important;
}

// Width 
.w-15{
    width: 15% !important;
}
.w-18{
    width: 18% !important;
}
.w-40 {
    width: 40% !important; 
}
.w-60{
    width: 60% !important;
}