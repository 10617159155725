@import "../../assets/style/variables";
// @import "../../assets/style/_typography";

.add-admin-model {
  .modal-dialog {
    .modal-content {
      background: transparent;
      border: none;
    }
  }
}

// Create pop up

.input-main {
  width: 508px;
  height: auto;
  min-height: 574px;
  background: #f1f2f3;
  border-radius: 10px;
  padding: 35px 0px;
  font-family: 'Montserrat', sans-serif;
}

.admin-input {
  width: 348px;
  height: 50px;
  background: #ffffff;
  border: 1.5px solid #efefef;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: #000000;
  text-indent: 15px;
  &::placeholder {
    color: #949494;
    font-weight: 500;
  }
}
.input-admin {
  display: flex;
  flex-direction: column;
}
.create-btn {
  width: 127px;
  height: 42px;
  background: #39b54a;
  border-radius: 8px;
  outline: none;
  border: none;
}

.cancle-btn {
 font-family: 'Montserrat', sans-serif;
  width: 70px;
  height: 24px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  color: #949494;
  margin-right: 5px;
}

.btn-cancel {
  width: 62px;
  height: 24px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  color: #949494;
}
.btn-save {
  width: auto;
  height: 42px;
  background: #39b54a;
  border: none;
  outline: none;
  border-radius: 8px;
  font-family: 'Montserrat', sans-serif;
}

.card-main {
  width: 473px;
  height: auto;
}


.btn-1 {
  outline: none;
  box-shadow: none;
}
