@import "../../assets/style/variables";
// @import "../../assets/style/typography";

// Login pages container
.login-pages {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: linear-gradient(to right, #B0C6FF, #C0B4F2, #FF8BB1);
    background: #f1f1f1;
}

.admin-logo {
    width: 120px;
    margin: 65px 0px 10px 0px;
}

.hey-there {
    margin: 10px 0px 65px 0px;
    font-weight: 700;
    font-size: 35px;
    line-height: 42px;
    font-family: 'Montserrat', sans-serif;
}

.forgot-pwd-text {
    margin: 15px 0px 60px 0px;
    font-size: 32px;
    font-weight: 700;
}

.forgot-pwd-desc {
    margin: 5px 0px 25px 0px;
    font-size: 24px;
    font-family: 'Montserrat';
    line-height: 28px;
    font-weight: 400;
}   

.form-inner {
    width: 445px;
    text-align: center;
    padding: 10px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 1px 2px 15px #8080806e;
}

.waving-hand {
    height: 26px;
    margin-right: 5px;
    position: relative;
    top: 2px;
}

// Login Buttons with Hover
.login-button {
    margin: 30px auto;
    height: 60px;
    width: 60px;
    background-color: #0d6efd;
    border: none;
    border-radius: 100%;
    display: block;
    cursor: pointer;
}

.forgot-pwd-button {
    margin: 30px auto;
    height: 60px;
    width: 60px;
    background-color: #0d6efd;
    border: none;
    border-radius: 100%;
    display: block;
    cursor: pointer;
}

.login-button:hover, .forgot-pwd-button:hover {
    background-color: #0d6efd;
}

.arrow-style {
    width: 20px;
    margin-left: 2px;
    margin-bottom: 1.5px;

}

.link-inner {
    margin: 5px 0px 30px 0px;
}

.forgot-password {
    color: #2a2828;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
}